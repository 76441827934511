var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import SearchBar from "../../components/searchBar/searchBar";
import { postData } from "../../utils/api.util";
import toast, { Toaster } from 'react-hot-toast';
import { IconButton } from "../../components/iconButton/iconButton";
import GoogleIcon from '@mui/icons-material/Google';
import { authWithGoogle, signOut } from "../../utils/firebase.util";
import { authUserAtom } from "../../atoms";
import { useAtom } from 'jotai/react';
import { Divider } from '@mui/material';
import "./App.css";
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import MuiIconButton from '@mui/material/IconButton';
// TODO: Separate component
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import MenuItems from '../../components/menuItems/menuItems';
import halalPlacesLogo from '../../assets/halalplaces.png';
var customIconButtonStyle = {
    // backgroundColor: "rgb(3, 27, 78)",
    margin: "20px 10px 10px 10px",
    border: "solid 1px black",
    textDecoration: "none",
    boxShadow: "none",
    borderRadius: "4px",
    width: "150px",
    color: "rgb(3, 27, 78)",
    fontWeight: "bold",
    // float: "right",
};
function App() {
    var _this = this;
    // TODO: Make a separate search page which also handles the results list
    var _a = React.useState(""), search = _a[0], setSearch = _a[1];
    var _b = React.useState([]), results = _b[0], setResults = _b[1];
    var _c = React.useState(false), loading = _c[0], setLoading = _c[1];
    // Import userAtom from the atoms.ts file
    var _d = useAtom(authUserAtom), user = _d[0], setUser = _d[1];
    // Dropdown state:
    var _e = React.useState(null), anchorEl = _e[0], setAnchorEl = _e[1];
    var open = Boolean(anchorEl);
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var handleSearchChange = function (e) {
        setSearch(e.target.value);
    };
    var userDropdownItems = [
        {
            label: "Sign Out",
            icon: _jsx(LogoutIcon, {}),
            onClick: function () { return signOut(setUser, null); }
        },
        {
            label: "Profile",
            icon: _jsx(AccountBoxIcon, {}),
            // Direct to data.halalscout.com.au/profile
            onClick: function () { return window.open("https://data.halalscout.com.au/profile", "_blank"); }
        }
    ];
    var searchPlaces = function () { return __awaiter(_this, void 0, void 0, function () {
        var places;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    // Handle null input
                    if (search === "") {
                        toast.error("Please enter a search term");
                        return [2 /*return*/];
                    }
                    if (!user.id) {
                        toast.error("Please sign in first");
                        return [2 /*return*/];
                    }
                    setLoading(true);
                    return [4 /*yield*/, postData(user.refreshToken, "/places/search", { search: search }, null, setUser)];
                case 1:
                    places = _a.sent();
                    console.log(places);
                    setResults(places);
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    // onClick: () => signOut(setUser, setJobs)
    return (_jsxs("div", { className: "App", children: [_jsxs("header", { className: "App-header", children: [_jsxs("div", { className: "profile-heading", style: { display: "flex", alignItems: "center", justifyContent: "space-between" }, children: [_jsx("img", { src: halalPlacesLogo, height: 40, width: 200, className: "App-logo", alt: "logo" }), user.id ?
                                _jsxs("div", { className: "menu-section", children: [_jsx(MenuItems, { items: userDropdownItems, open: open, handleClose: handleClose, anchorEl: anchorEl }), _jsx(MuiIconButton, { onClick: handleClick, style: {
                                                marginRight: "10px",
                                            }, children: _jsx(Avatar, { "aria-controls": open ? 'dropdown-menu' : undefined, "aria-haspopup": "true", "aria-expanded": open ? 'true' : undefined, alt: user.name, src: "".concat(user.photoURL, "?_=").concat(+new Date()), style: {
                                                    width: "40px",
                                                    height: "40px",
                                                } }) })] })
                                :
                                    _jsx(IconButton, { customIconButtonStyle: customIconButtonStyle, onClick: function () { return authWithGoogle(setUser); }, content: "Sign In", icon: _jsx(GoogleIcon, {}) })] }), _jsx(Divider, { variant: "middle", style: { marginTop: "10px" } }), _jsxs("div", { className: 'search-bar-container', children: [_jsx(SearchBar, { searchProducts: function () { return searchPlaces(); }, value: search, onChange: handleSearchChange }), _jsx("span", { className: "search-span", children: " i.e Halal Food in Sydney, Vegan Food near Kingsford " })] }), loading ?
                        // TODO: Find a real loading component
                        _jsx("p", { children: "Loading..." })
                        :
                            results.map(function (result, index) {
                                return (_jsx("div", { className: "result", style: { display: 'flex', justifyContent: 'center' }, children: _jsxs(List, { sx: { width: '100%', maxWidth: 560, bgcolor: 'background.paper' }, children: [_jsxs(ListItem, { children: [_jsx(ListItemAvatar, { children: _jsx(Avatar, {}) }), _jsx(ListItemText, { primary: result.displayName.text, secondary: result.formattedAddress })] }), _jsx(Divider, { variant: "inset", component: "li" })] }) }, index));
                            })
                    // results.map((result: any, index: number) => {
                    //   return (
                    //     <div key={index} className="result">
                    //       <p>{result.displayName}</p>
                    //       <p>{result.formattedAddress}</p>
                    //       <p>{result.priceLevel}</p>
                    //     </div>
                    //   )
                    // })
                ] }), _jsx(Toaster, {})] }));
}
export default App;
