import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import "./searchBar.css";
var SearchBar = function (_a) {
    var value = _a.value, searchProducts = _a.searchProducts, onChange = _a.onChange;
    return (_jsxs(_Fragment, { children: [_jsx("div", { style: {
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100vh",
                    zIndex: "-1",
                } }), _jsx("form", { onSubmit: function (e) {
                    // searchProducts()
                    e.preventDefault();
                    searchProducts();
                }, className: "form", children: _jsxs("div", { className: "input-container def", children: [_jsx("div", { style: { width: "20px", height: "20px", marginRight: "5px" }, children: _jsx("svg", { focusable: "false", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", style: {
                                    fill: "#9aa0a6",
                                    paddingLeft: "8px",
                                    paddingTop: "0.5px",
                                }, children: _jsx("path", { d: "M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" }) }) }), _jsx("div", { className: "child-input-container", children: _jsx("input", { type: "text", className: "input h-full", value: value, onChange: onChange }) })] }) })] }));
};
export default SearchBar;
